<template>
  <div class="page-wrapper white">
    <div class="page-info search-wrapper w-200">
      <div class="search-item">
        <el-select v-model="formData.school_id" placeholder="请选择校区" size="small">
          <el-option v-for="item of school" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <el-select v-model="formData.grade_id" placeholder="请选择年级" size="small">
          <el-option v-for="item of grade" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <el-select v-model="formData.check_id" placeholder="选科组合" size="small">
          <el-option v-for="item of subject" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <el-button @click="onDownload" size="small" type="primary" :disabled="disabled">
          <i class="el-icon-download"></i>
          <span>导出</span>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getApi2, fileDownload } from '@/api'
export default {
	_config:{"route":{"path":"index","meta":{"title":"操作"}}},
  data() {
    return {
      formData: {
        school_id: '',
        grade_id: '',
        check_id: ''
      },
      school: [],
      grade: [],
      subject: []
    }
  },
  computed: {
    disabled() {
      let isDisabled = false
      Object.keys(this.formData).forEach(key => {
        if (this.formData[key] || this.formData[key] === 0) {
          return
        }
        isDisabled = true
      })
      return isDisabled
    }
  },
  created() {
    this.getOptions()
  },
  methods: {
    getOptions() {
      getApi2('/common/screen/school').then(res => {
        this.school = res.map(item => ({
          value: item.id,
          label: item.school_name
        }))
        if (this.school.length) {
          this.school.unshift({ label: '全部', value: 0 })
        }
      })
      getApi2('/common/screen/grade').then(res => {
        this.grade = res.map(item => ({
          value: item.id,
          label: item.grade_name
        }))
        if (this.grade.length) {
          this.grade.unshift({ label: '全部', value: 0 })
        }
      })
      getApi2('/tool/leading-out/check-subject').then(res => {
        this.subject = res.map(item => ({
          value: item.check_id,
          label: item.check_subject_name
        }))
      })
    },
    onDownload() {
      this.$confirm('确认导出数据?', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        fileDownload('/api2/api/tool/leading-out/student-subject', {}, { httpType: 'post', data: { ...this.formData }, name: '学生选科数据', show: true })
      })
    }
  }
}
</script>
<style lang="scss" scoped></style>
